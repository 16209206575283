body,
html {
    height: 100%;
    width: 100%;
}

#app {
    position: relative;
    height: 100vh;
    width: 100%;
    font-family: "ORC A STD Regular";
}

#bg-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-image: url("background1.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    filter: blur(0px);
    -webkit-filter: blur(0px);

    z-index: -1;
}

.fill {
    min-height: 100%;
    height: 100%;
}

.dark-blur {
    background-color: rgba(33, 37, 41, 0.85) !important;
    backdrop-filter: blur(6px) !important;
}

#home-center-text {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
    color: rgb(230, 230, 230);
    text-align: center;
    border-top: 2px solid rgb(230, 230, 230);
    border-bottom: 2px solid rgb(230, 230, 230);
}

@font-face {
    font-family: "ORC A STD Regular";
    src: url("OCR_A_Std_Regular.ttf");
}

#intro-p {
    font-family: Helvetica, sans-serif;
    line-height: 30px;
    font-size: 13px;
}

#intro-title {
    line-height: 30px;
    font-size: 13px;
}

#resume-link {
    font-size: 12px;
}

#about-p {
    font-family: Helvetica, sans-serif;
    line-height: 30px;
    font-size: 13px;
    color: white;
    text-indent: 40px;
}

#contact-center-div {
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.5);
    color: rgb(230, 230, 230);
    text-align: left;
    border: 2px solid rgb(230, 230, 230);
    backdrop-filter: blur(6px) !important;
}

.white-hover:hover {
    color: rgb(190, 190, 190);
}
